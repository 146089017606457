<template>
    <el-card class="evaluationProject mainContent">
        <div class="search-box">
            <el-form ref="searchForm" :model="searchParams" inline>
                <el-form-item>
                    <el-date-picker
                        v-model="time"
                        class="w300px"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        @change="changeTime"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="doSearch">搜索</el-button>
                </el-form-item>
            </el-form>
            <div class="btn-box">
                <el-button type="primary" @click="doHandle('add', null)"> 新增 </el-button>
                <el-button type="primary" plain @click="doExport">导出</el-button>
            </div>
        </div>
        <el-table v-loading="loading" style="width: 100%; margin-top: 10px" :data="tableData" border :header-cell-style="headerCellStyle">
            <template slot="empty">
                <IsEmpty />
            </template>
            <el-table-column type="index" label="序号" width="50" align="center" />
            <el-table-column prop="projectName" label="项目名称" align="center" />
            <el-table-column prop="createAccountName" label="创建人姓名" show-overflow-tooltip align="center" />
            <el-table-column prop="projectTemplateName" label="评估模板" show-overflow-tooltip align="center" />
            <el-table-column prop="cycle" label="项目周期" width="250" show-overflow-tooltip align="center" />
            <el-table-column prop="directorAccountName" label="项目负责人" show-overflow-tooltip align="center" />
            <el-table-column prop="assessUnit" label="评估机构" show-overflow-tooltip align="center" />
            <el-table-column prop="status" label="状态" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                    <span :style="{ color: scope.row.status === 0 ? '#ffc100' : '#3fcf89' }">
                        {{ ['进行中', '已完成'][scope.row.status] }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="pop" label="操作" width="400" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button type="text" @click="doHandle('see', scope.row)">查看</el-button>
                    <el-button type="text" @click="doHandle('edit', scope.row)">编辑</el-button>
                    <el-button
                        type="text"
                        :style="{ color: scope.row.state === 0 ? 'rgb(255, 90, 89)' : '#3fcf89' }"
                        @click="changeState(scope.row)"
                    >
                        {{ scope.row.state === 0 ? '禁用' : '启用' }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            @size-change="doSearch"
            @current-change="getList"
            :current-page="queryParams.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total"
        >
        </el-pagination>
    </el-card>
</template>

<script>
import { evaluationProjectList, projectExport, govern } from '@/api/evaluationProject'
import { downloadStream } from '@/utils/index'
export default {
    // 评估项目
    name: 'evaluationProject',
    data() {
        return {
            searchParams: {
                startTime: '',
                endTime: ''
            },
            time: '',
            queryParams: {
                pageNum: 1,
                pageSize: 10
            },
            total: 1,
            tableData: [],
            loading: false,
            visibleUpload: false
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        // 选择时间
        changeTime(val) {
            if (val) {
                this.searchParams.startTime = val[0]
                this.searchParams.endTime = val[1]
            } else {
                this.searchParams.startTime = ''
                this.searchParams.endTime = ''
            }
        },
        // 搜索
        doSearch() {
            this.queryParams.pageNum = 1
            this.getList()
        },
        // 获取列表数据
        getList() {
            this.loading = true
            evaluationProjectList({ ...this.searchParams, ...this.queryParams })
                .then(res => {
                    this.tableData = res.data.records
                    this.total = res.data.total
                    this.loading = false
                })
                .catch(() => {
                    this.tableData = []
                    this.total = 0
                    this.loading = false
                })
        },
        // 评估项目导出
        async doExport() {
            const res = await projectExport()
            downloadStream(res, `评估项目.xlsx`)
        },
        // 操作
        doHandle(type, row) {
            this.$router.push({
                path: '/evaluationProject/evaluationProjecthHandle',
                query: {
                    handleType: type,
                    id: row?.id
                }
            })
        },
        // 更改状态
        changeState(row) {
            this.$confirm(`此操作将${row.state === 0 ? '禁用' : '启用'}该项目, 是否继续`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    govern({ id: row.id, state: row.state === 0 ? 1 : 0 }).then(res => {
                        this.$message.success(res.data)
                        this.getList()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    })
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.evaluationProject {
    .search-box {
        display: flex;
        justify-content: space-between;
    }
}
</style>
